<template>
<div >
  <slot name="header">jkjjhkjhjk</slot>
  <v-row no-gutters class="tw-p-3 lg:tw-p-14" >
    <v-col cols="3" class="tw-hidden lg:tw-block">
      <div class="tw-bg-white tw-fixed">
        <slot  name="dashboard-sideNav"></slot>
      </div>
    </v-col>
    <v-col sm="12"  md="9">
      <div class="tw-pr-4"  >
        <slot name="dashboard-content">njkjkkkkjjk</slot>
      </div>
    </v-col>

  </v-row>
  <BottomNavigation/>
</div>
</template>

<script>
import BottomNavigation from "@/components/dashboard/reuseables/BottomNavigation";
export default {
name: "RecruiterDashboardLayout",
  components: {BottomNavigation},
}
</script>


<style scoped>
.main__content {
  min-height: calc(100vh - 64px);
}

.scroll {
  max-height: 87vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}
.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 15px !important;
  border-radius: 6px;
}

</style>
