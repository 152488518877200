<template>
  <RecruiterDashboardLayout>
    <template #header>
      <Header>
        <template #right-header-component>
          <ProfileDropDown />

        </template>
        <template #route-back-component v-if="!$route.meta.isDashboard">
          <div>
            <vue-feather type="chevron-left" @click="$router.back()"></vue-feather>
          </div>
        </template>
      </Header>
    </template>
    <template #dashboard-sideNav>
      <CompanyProfileName/>
      <div class="tw-mt-6">
        <VerticalMenu/>
      </div>
    </template>
    <template #dashboard-content>
      <router-view/>
    </template>
  </RecruiterDashboardLayout>
</template>

<script>
import RecruiterDashboardLayout from "@/components/dashboard/layouts/RecruiterDashboardLayout";
import Header from "@/components/onboarding/reuseables/Header";
import ProfileDropDown from "@/components/dashboard/reuseables/ProfileDropDown";
import VerticalMenu from "@/components/dashboard/reuseables/VerticalMenu";
import CompanyProfileName from "@/components/dashboard/reuseables/CompanyProfileName";

export default {
  name: "RecruiterHomeView",
  components: {CompanyProfileName, VerticalMenu, ProfileDropDown, Header, RecruiterDashboardLayout},
  methods: {
    // reloadData() {
    //   window.location.reload();
    // }
  }
}
</script>

<style scoped>
.name {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #475661;
}


</style>
