import {mapState} from "vuex";
import {viewFile} from "@/services/storage/aws";

export default {
    data: () => ({
        image: ''
    }),
    computed: {
        ...mapState('editCompany', ['companyProfile'])
    },
    watch: {
        companyProfile: {
            handler: function (profile) {
                if (!profile.company_logo) return
                viewFile({url: profile.company_logo, context: this})
            },
            deep: true
        }
    }
}