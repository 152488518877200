<template>
  <Loader :loading="loading" v-if="Object.keys(getCompanyProfile).length === 0"/>
  <div v-else style="margin-top: 60px">
    <div class="tw-flex">
      <span v-if="getCompanyProfile.company_logo" class=" tw-mr-3"><img :src="image"></span>
      <div v-else class="avatar-gray tw-mr-3 d-flex align-center justify-center pa-4">
        <span
            class="comp_initial text-capitalize">{{ getCompanyProfile.company_name && getCompanyProfile.company_name.charAt(0) }}</span>
      </div>
      <div>
        <h4 class="company_name text-capitalize">{{ getCompanyProfile.company_name }}</h4>
        <div :style="{background: getCompanyProfile.verified ?'#EEF8FF':'#F4F7F8', borderRadius: '3px'}"
             class="d-flex align-center mt-2 justify-center py-1 px-2"
             style="cursor: pointer">
          <h4 :style="{color: getCompanyProfile.verified ?'#008EEF':'#9CABB5'}" class="ml-1 verifyText">
            {{ getCompanyProfile.verified ? 'VERIFIED' : 'UNVERIFIED' }}</h4>
        </div>
      </div>
    </div>
    <div class="view tw-flex tw-items-center tw-justify-center tw-px-6 tw-py-2" @click="goto">
      <p>View Profile</p>
      <ArrowRightIcon class="arrow-class"/>
    </div>


  </div>

</template>

<script>
import {ArrowRightIcon} from 'vue-feather-icons'
// import {getCompanyProfile} from "@/services/api/APIService";
import {mapGetters} from "vuex";
import companyImage from "@/mixins/companyImage";
import Loader from "@/components/UIComponents/reusablesIcon/Loader";

export default {
  name: "CompanyProfileName",
  components: {Loader, ArrowRightIcon},
  mixins:[companyImage],
  data() {
    return {
      companyDetails: {},
      verified: false,
      loading: true
    }
  },
  computed: {
    ...mapGetters('editCompany', ['getCompanyProfile']),
  },
  methods: {
    // getCompanyProfile() {
    //   return getCompanyProfile(this.$siteName())
    //       .then(res => {
    //         this.companyDetails = res.data
    //       })
    //       .catch(err => {
    //         console.log(err)
    //       })
    // },

    goto() {
      this.$router.push({name: 'EditCompanyProfileView', params: {tab: "description"}})
    }

  },
  mounted() {
    console.log(this.getCompanyProfile)
    // this.getCompanyProfile()
  }

}
</script>

<style scoped>
img {
  height: 50px !important;
  width: 50px !important;
}

.avatar-gray {
  width: 26px;
  height: 26px;
  background: #8F47F4;
  border-radius: 2px;
}

.company_name {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #2B1C1C;
}

.view {
  margin-top: 15px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(56, 113, 131, 0.08);
  border-radius: 4px;
  cursor: pointer;
  width: 168px;
  height: 38px;
}

.view p {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #958D8D;
  margin-bottom: 0 !important;
}

.arrow-class {
  color: #958D8D;
  width: 16px;
  height: 16px;
  margin-left: 4px;
}

.verifyText {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

}

.comp_initial {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;

  color: #FFFFFF;

}
</style>
